/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react'

import Tippy from '@tippyjs/react'

import AnimatedSectionTitle from '~/components/AnimatedSectionTitle'
import Image from '~/components/Image'
import ScrollReveal from '~/components/ScrollReveal'

import 'tippy.js/animations/shift-toward-extreme.css'
import * as style from './styles'

const PARTNERS = [
  {
    image: 'partnerSowlutions',
    name: 'Sowlutions Inc.',
    url: 'https://Sowlutions.com/',
  },
  {
    image: 'partnerJosephAnthony',
    name: 'Joseph Anthony Taouk',
    url: 'https://JosephTaouk.com/',
  },
]

const SectionPartners = () => {
  return (
    <section>
      <AnimatedSectionTitle code='awesome_partners'>
        Our Partners
      </AnimatedSectionTitle>

      <div css={style.partnersContainer}>
        <ScrollReveal>
          {
            PARTNERS.map(({ image, name, url }) => (
              <Fragment key={`partner-${name}`}>
                <Tippy
                  content={name}
                  placement='bottom'
                  arrow={false}
                  animation='shift-toward-extreme'
                  followCursor
                  css={style.tippyTooltip}
                >
                  <a
                    name={name}
                    title={name}
                    href={url}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <Image image={image} css={style.partnerImage} />
                  </a>
                </Tippy>
              </Fragment>
            ))
          }
        </ScrollReveal>
      </div>
    </section>
  )
}

export default SectionPartners
